// WARNING: This is the old stylesheet and should no longer be used
// For new mini-sites, please create a theme page under /resources/assets/sass/themes
// and use the new design system imports

@import "/bower_components/Skeleton/css/skeleton.css";

@import "utils/_mixins";
@import "utils/_variables";
@import "style-guide/responsive";
@import "style-guide/variables";

//futurefont is in mixins due to wordspacing
$openSansFont: 'Open Sans', sans-serif;
//$futureFont: "Michroma", "Open Sans", Helvetica, sans-serif;
$futureFont: "Noto Sans", Arial, Helvetica, sans-serif;
$notoSansFont: "Noto Sans", Arial, Helvetica, sans-serif;
$notoSansFontSC: "Noto Sans SC", "Noto Sans", Arial, Helvetica, sans-serif;
$notoSansFontTC: "Noto Sans TC", "Noto Sans", Arial, Helvetica, sans-serif;
$notoSansFontJP: "Noto Sans JP", "Noto Sans", Arial, Helvetica, sans-serif;
$notoSansFontKR: "Noto Sans KR", "Noto Sans", Arial, Helvetica, sans-serif;

$fontSizeSm: 12px;
$mainHeaderHeight: 45px;
$storeHeaderHeight: 45px;
$titleSize: 40px;

div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; font: inherit; vertical-align: baseline; }

html { line-height: 1; }

ol, ul { list-style: none; }

table { border-collapse: collapse; border-spacing: 0; }

caption, th, td { text-align: left; font-weight: normal; vertical-align: middle; }

q, blockquote { quotes: none; }
q:before, q:after, blockquote:before, blockquote:after { content: ""; content: none; }

a img { border: none; }

.bold {
    font-weight: bold;
}

.desktopOnly {
    //nothing. uses default display type
}
.desktopOrTablet {
    //nothing. uses default display type
}
.tabletOnly {
    display: none;
}
.tabletOrMobile {
    display: none;
}
.mobileOnly {
    display: none;
}

@include breakpoint-tablet-only {
    .desktopOnly {
        display: none;
    }
    .tabletOnly {
        display: unset;
    }
    .tabletOrMobile {
        display: unset;
    }
}

@include breakpoint-mobile-only {

    .desktopOnly {
        display: none;
    }
    .desktopOrTablet {
        display: none;
    }
    .tabletOnly {
        display: none;
    }
    .tabletOrMobile {
        display: unset;
    }
    .mobileOnly {
        display: unset;
    }
}


article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary { display: block; }

#onetrust-consent-sdk{ //Override skeleton.css button styles
    button {
        padding: 0;
        line-height: unset;
    }
}

.grecaptcha-badge {
    z-index: 520;
}

//replace skeleton container
.container {
    position: relative;
    width: 100%;
    max-width: 960px;
    min-height: 70vh;    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
    @media (min-width: 550px) {
        width: 80%;
    }
    @media (min-width: 400px) {
        width: 85%;
        padding: 0;
    }
}

* {
    box-sizing: border-box;
    margin: 0;
}

body,html{
    height: 100%;
}

body {
    font-size: 14px;
    font-weight: normal !important;
    font-family: $notoSansFont;
    line-height: 1.2;
    background-color: #ececec;
    color: $fontColor;
}

/*Change skeleton width*/
.container {
    max-width: 1140px !important;
}

a {
    color: $pcRed;
    text-decoration: none;

    &:hover {
        color: $black;
    }
}

.errors {
    padding: 20px 0;
}

.strikeout {
    text-decoration: line-through;
}

textarea, input {
    font-family: $notoSansFont;
    font-weight: normal;

    &.error{
        border: 1px solid red !important;
    }
}

.wrapper {
}

#footer, .push {
    min-height: 210px;
}

.create-news-item {
    textarea {
        height: 500px;
    }
}

.read-news-item {
    .post {
        .post-image {
            padding-bottom: 20px;
        }
    }
}

.pagination {
    li {
        display: inline-block;
        padding: 0 5px;
    }
}

.translation {
    background-color: #f0f0f0;
}

input, select {
    border: 1px solid $black;
    border-radius: 0;
}

.clear {
    clear: both;
}

button, .button {
    // Undo everything Skeleton adds
    display: inline-block;
    height: unset;
    padding: unset;
    color: unset;
    text-align: unset;
    font-size: unset;
    font-weight: unset;
    line-height: unset;
    letter-spacing: unset;
    text-transform: unset;
    text-decoration: unset;
    white-space: unset;
    background-color: unset;
    border-radius: unset;
    margin: unset;
    border: 1px solid #bbb;
    cursor: pointer;
    box-sizing: border-box;
}

.LegacyPageContainer {
    button:not(.Button), .button {
        @include button('.button-wrap');

        &:disabled {
            background: #ababab;
        }

        a {
            display: block;
            width: auto;
        }
    }
}

h2, .h2 {
    @include notoSansFont;
    @extend .h1;
    font-size: 28px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: unset;
}

h1, .h1 {
    @extend .h2;
    color: $pcRed;
    font-size: 36px;
    //font-weight: normal;
    letter-spacing: unset;
}

h3, .h3 {
    @extend .h2;
    font-size: 38px;
}

h4 {
    font-weight: bold;
    letter-spacing: unset;
}

.contentHeaderContain {
    margin: 0 auto;
    display: inline-block;
    width: 100%;
    margin-top: 40px;

    h2,p{
        color: $pcRed;
        margin: 0 auto;
        margin-top: -9px;
        margin-bottom: 40px;
        font-size: 25px;
        text-transform: uppercase;
        text-align: center;
    }

    h2 {
        margin-top: -18px;
        font-size: 25px;
    }

    p{
        margin-top: -9px;
        font-size: 15px;
    }

    .contentHeaderLines{
        margin-top: 25px;
        width: 21%;
        float: left;
        height: 3px;
        margin: 0 auto;
        border-top: 1px solid $pcRed;
        border-bottom: 1px solid $pcRed;
    }

    .contentFooterLines {
        margin-top:30px;
        width:44%;
        float: left;
    }

    .contentLineRight {
        float: right;
    }

    .lotusLogo {
        height: 48px;
        width: 83px;
        margin: 0 auto;
        margin-top: -33px;
        margin-bottom: 50px;
        background-repeat: no-repeat;
        background-image: url('/images/redLotus.png');
    }
}

.container.errors{
    position: relative;
    z-index: 50;
    top: 160px;
    background: #cc0000;
    color: white;
    padding: 30px;
}

#fullScreenDownArrow{
    //@include animation(bounce 2s infinite);
    animation: bounce 2s infinite;
    position: fixed;
    bottom: 20px;
    left: 50%;
    z-index:10;
    background:#111;
    width:10em;
    height:10em;
    padding:4em 2em;
    border-radius:50%;
    font-size:0.5em;
    display:block;
    box-shadow:0px 0px 5px 0px #333;

    @include mq(mobile) {
        left: 43%;
    }

    .arrow {
        float:left;
        position:relative;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 3em 3em 0 3em;
        border-color: #ffffff transparent transparent transparent;
        -webkit-transform:rotate(360deg)
    }

    .arrow:after {
        content:'';
        position:absolute;
        top:-3.2em;
        left:-3em;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 3em 3em 0 3em;
        border-color: #111 transparent transparent transparent;
        -webkit-transform:rotate(360deg)
    }
}

//@include keyframes(bounce) {
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        //@include transform(translateY(0));
        transform: translateY(0);
    }
    40% {
        //@include transform(translateY(-30px));
        transform: translateY(-30px);
    }
    60% {
        //@include transform(translateY(-15px));
        transform: translateY(-15px);
    }
}

.cd-hero {
        position: relative;

        &.slider {
            border-bottom: 6px solid $warframeRed;

            .slide {
                max-height: 600px;
                background-size: cover !important;
                background-color: #000 !important;
                background-repeat: no-repeat !important;
                background-position: top center !important;

                @include mq(hd) {
                    max-height: 660px;
                }
                li {
                    display: inline-block;
                    //@include transform(rotateZ(45deg));
                    transform: rotateZ(45deg);
                    width: 14px;
                    height: 14px;
                    border: 2px solid #ffffff;
                    margin: 12px 20px 0 20px;
                    color: transparent;
                    cursor: pointer;
                    list-style: none;

                    &.selected{
                        z-index: 5 !important;
                    }

                    img {
                        min-width: 100%;
                    }
                }
            }
        }

        .slider-nav {
            @include horizontal-align-absolute();
            bottom: 0;
            height: 34px;
            z-index: 10;
            @include corner-triangle-bookends(36px, $warframeRed);

            ul {
                display: inline-block;
                background-color: $warframeRed;
                height: 36px;
                margin: 0px -4px;

                li {
                    display: inline-block;
                    transform: rotateZ(45deg);
                    width: 14px;
                    height: 14px;
                    border: 2px solid #ffffff;
                    margin: 12px 20px 0 20px;
                    color: transparent;
                    cursor: pointer;
                    list-style: none;

                    &.selected {
                        z-index: 5 !important;
                    }

                    &.selected::before {
                        content: '';
                        position: absolute;
                        display: block;
                        width: 100%;
                        height: 100%;
                        background-color: #ffffff;
                        border: 3px solid $warframeRed;
                        border-radius: 50%;
                        box-sizing: border-box;
                    }
                }
            }
        }

        .slide {
            &.selected {
                z-index: 5 !important;
            }

            .text {
                @include notoSansFont;
                @extend .h3;
                color: $white;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding: 20px;
                text-align: left;

                video {
                    z-index: -1;
                    position: absolute;
                    top: -80px;
                    min-height: 521px;
                    left: 50%;
                    transform: translate(-50%, 0);
                    min-width: 1920px;
                    width: 100%;
                }

                .slide-content {
                    height: 100%;

                    img {
                        min-width: 0;
                        margin-bottom: 20px;
                    }

                    a {
                        text-decoration: underline;
                    }
                }
            }
        }

        .slide {
            .container {
                height: 100%;

                .slide-template {
                    position: absolute;

                    &.top-left {
                        top: 0;
                        left: 0;
                    }

                    &.top-right {
                        top: 0;
                        right: 0;
                    }

                    &.bottom-left {
                        bottom: 0;
                        left: 0;
                    }

                    &.bottom-right {
                        bottom: 0;
                        right: 0;
                    }
                }
            }
        }
    }

.cd-hero2 {
        position: relative;

        &.slider {
            //border-bottom: 6px solid $warframeRed;
            .slide {
                max-height: 600px;
                background-size: cover !important;
                background-color: #000 !important;
                background-repeat: no-repeat !important;
                background-position: top center !important;

                @include mq(hd) {
                    max-height: 660px;
                }

                img {
                    min-width: 100%;
                }
            }
        }

        .slider-nav {
            @include horizontal-align-absolute();
            //@include corner-triangle-bookends(36px, $warframeRed);
            bottom: 2px;
            height: 34px;
            z-index: 10;

            ul {
                display: inline-block;
                //background-color: $warframeRed;
                height: 36px;
                margin: 0px -4px;

                li {
                    display: inline-block;
                    width: 14px;
                    height: 14px;
                    border: 1px solid #ffffff;
                    border-radius: 50%;
                    margin: 12px 10px 0 10px;
                    color: transparent;
                    cursor: pointer;
                    list-style: none;

                    &.selected {
                        z-index: 5 !important;
                        background-color: white;
                    }
                }
            }
        }

        .slide {
            &.selected {
                z-index: 5 !important;
            }

            .text {
                @include notoSansFont;
                @extend .h3;
                color: $white;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding: 20px;
                text-align: left;
                box-shadow: inset 0px -250px 100px -100px rgba(0, 0, 0, 0.8);

                video {
                    z-index: -1;
                    position: absolute;
                    top: -80px;
                    min-height: 521px;
                    left: 50%;
                    transform: translate(-50%, 0);
                    min-width: 1920px;
                    width: 100%;
                }

                .slide-content {
                    height: 100%;

                    img {
                        min-width: 0;
                        margin-bottom: 20px;
                    }

                    p {
                        font-family: $notoSansFont;
                        margin: 0;
                        font-size: 14px;
                        text-transform: none;
                        line-height: 1em;
                        //padding-bottom: 20px;
                        word-spacing: 0;
                    }

                    a {
                        text-decoration: underline;

                        &:hover {
                            color: $pcRed;
                        }
                    }
                }
            }
        }

        .slide {
            .container {
                height: 100%;

                .slide-template {
                    position: absolute;

                    &.top-left {
                        bottom: 20px;
                        left: 0;
                    }

                    &.top-right {
                        bottom: 0;
                        left: 0;
                    }

                    &.bottom-left {
                        bottom: 0;
                        left: 0;
                    }

                    &.bottom-right {
                        bottom: 0;
                        left: 0;
                    }
                }
            }
        }
    }

form {

    label{
        &[for="email"],&[for="email_confirm"],&[for="email_confirm"],&[for="alias"],&[for="password"],&[for="password_confirm"],&[for="password_confirm"]{
            display: none;
        }
    }

    fieldset{
        .form-text{
            border: 1px solid #d9d9d9;
            border-radius: 0;
            background-color: #e9e9e9;
            height: 50px;
            width: 100%;
            margin-bottom: 15px;
        }

        input[type="checkbox"]{
            position: relative;
            float: left;
            margin-right: 9px;
            top: 4px;
        }

        label.error{
            color: red;
            margin-bottom: 10px;
        }
    }

    [data-tip] {
        position:relative;

        &:before,&:after{
            display:none;
            position:absolute;
            top: 0;
        }

        &:after {
            content:'';
            border-top: 14px solid transparent;
            border-bottom: 14px solid transparent;
            border-right: 14px solid #1a1a1a;
            right:-14px;
            z-index:8;
            font-size:0;
            line-height:0;
            width:0;
            height:0;
        }

        &:before {
            content:attr(data-tip);
            right: -230px;
            width: 200px;
            padding:5px 8px;
            background:#1a1a1a;
            color:#fff;
            z-index:9;
            font-size: 0.75em;
            line-height:18px;
            word-wrap:normal;
            height: auto;
            text-align: center;
        }

        &:hover:before, &:hover:after {
            display:block;
        }
    }
}

@media (max-width: 1200px) {
    .contentHeaderLines{
        width: 29% !important;
    }
    .contentFooterLines{
        width: 42% !important;
    }
}

@media (max-width: 900px) {
    .contentHeaderLines{
        width: 22% !important;
    }
    .contentFooterLines{
        width: 40% !important;
    }
}

@media (max-width: 700px) {
    .contentHeaderLines{
        width: 16% !important;
    }
    .contentFooterLines{
        width: 37% !important;
    }
}

@media (max-width: 480px) {
    .contentHeaderLines{
        width: 12% !important;
    }
    .contentFooterLines{
        width: 32% !important;
    }
}

@media (max-width: 360px) {
    body.news_index #mainContent{
        width: 374px;
    }
}

@media (max-width: 320px) {
    body.news_index #mainContent{
        width: 374px;
    }
}


#colorbox{
    #cboxTopLeft,#cboxTopCenter,#cboxTopRight,#cboxMiddleLeft,#cboxMiddleRight,#cboxBottomLeft,#cboxBottomCenter,#cboxBottomRight,#cboxClose{
        width: 0 !important;
        height: 0 !important;
        display: none !important;
    }
    #cboxContent{
        background: none !important;
    }

    /*Colorbox*/
    #cboxLoadedContent {
        border: 1px solid $black;
        background: $white;
        margin: 0 !important;

        .inner {
            padding: 20px;
        }
    }
}

.alert {
    @include center(true, false);
    position: fixed;
    z-index: 1000;
    width: 500px;
    padding: 20px;
    background: rgba(255,255,255,0.9);
    color: black;
    font-weight: bold;
    text-transform: uppercase;
    top: 110px;

    a {
        text-decoration: underline;
    }

    .close{
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
        color: #000;
        font-size: 17px;
        text-decoration: none;
    }

    &.alert-success {
        //color: #6DB4CB;
        border-bottom: 4px solid #6DB4CB;
    }
    &.alert-danger {
        color: $pcRed;
        border-bottom: 4px solid $pcRed;
    }

    @include mq(tablet) {
        width:100%;
        top: 131px;
        text-align: center;
    }
}
.aliasOption {
  cursor: pointer;
}

.xsollaCallout{
    color: #fff;
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 5;
    margin: 10px auto;
    background: rgba(0,0,0,0.7);
    line-height: 16px;

    img,span{
        display: inline-block;
    }
    span{
        padding: 14px;
    }

    @include mq(mobile){
        width: 100%;
        padding:10px;
        max-width: 90vw;

        img,span{
            display: block;
            float:none;
            text-align: center;
            margin: 0 auto;
        }
        span{
            padding:0;
            margin: 10px auto;
        }
    }
}

//Positioning
.h-center {
    @include horizontal-align-absolute();
}

//body.lang-tr, body.lang-ru, body.lang-pl {
//    h2, .h2, h1, .h1, h2, .news-read .article-header .title, h3, .h3, .cd-hero .slide .text, #newsIndex .featured .post .title, #newsIndex .post-list .post .title {
//        font-family: 'Montserrat', sans-serif;
//    }
//}
body.lang-zh-hans {
    font-family: $notoSansFontSC;
    textarea, input {
        font-family: $notoSansFontSC;
    }
}

body.lang-zh-hant {
    font-family: $notoSansFontTC;
    textarea, input {
        font-family: $notoSansFontTC;
    }
}

body.lang-ja {
    font-family: $notoSansFontJP;
    textarea, input {
        font-family: $notoSansFontJP;
    }
}

body.lang-ko {
    font-family: $notoSansFontKR;
    textarea, input {
        font-family: $notoSansFontKR;
    }
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.recaptchaHolderCheckbox {
    margin-top: 20px;
    margin-bottom: 20px;

    > div > div {
        margin: 0 auto;
    }

    .recaptcha-disclaimer {
        margin-top: 20px;
    }
}
.recaptchaHolderInvisible {
    .recaptcha-disclaimer {
        margin-bottom: 20px;
    }
}

.recaptcha-disclaimer {
    margin-top: 20px;
    font-size: 12px;

    a {
        text-decoration: underline;
    }
}

.grecaptcha-badge {
    visibility: hidden;
}

#forgotpwForm {
    .recaptchaHolderCheckbox > div > div {
        margin: 0;
    }
}

@import "utils/_cdslider";
@import "_header.scss";
@import "_fixed_footer";
@import "_notification";
@import "account/_signup";
@import "account/_login";
@import "account/_account2023";
@import "account/_cross_compare";
@import "account/_cross_progression";
@import "account/_thankyou";
@import "account/_lightbox_forms";
@import "pages/_amazon";
@import "pages/_twitch";
@import "pages/_extension";
@import "pages/_gamepage";
@import "pages/_mobileapp";
@import "pages/_warframes";
@import "pages/_fansitespage";
@import "pages/_community";
@import "pages/_textpage";
@import "pages/_buildnotes";
@import "pages/_creators";
@import "pages/404";
@import "purchase/_buywithplat";
@import "purchase/_price_compare_template";
/*@import "purchase/_test_supporter_pack";*/
@import "purchase/_promocodes";
@import "purchase/_primeaccess";
@import "purchase/_purchase_thank_you";
@import "purchase/_buyplat_cardflip";
@import "promo/android-beta.scss";

// WARNING: This is the old stylesheet and should no longer be used
// For new mini-sites, please create a theme page under /resources/assets/sass/themes
// and use the new design system imports

@include popup-payment-selector();
